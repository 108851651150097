import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import { useWindowSize, useIsMobileScreen } from "../../Utilities/WindowSize";
import { useSelector, useDispatch } from "react-redux";

import { Spin } from "antd";

import {
  Appointment,
  fetchAppointments,
  WorkHours,
  WorkshopService,
  Items,
} from "shared";

import {
  getAppointments,
  getNotificationsNumber,
  setCurrentSection,
} from "../../../stores";

import { CurrentUser } from "../../../context/UserContext";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
  getEarliestOpeningTime,
  getLatestClosingTime,
  subtractHalfHourIfHalf,
  addHalfHourIfHalf,
} from "../../Utilities/Dates";

import CalendarHeader from "./Header/index";
import TimeGridDayCard from "./TimeGridDayCard";
import TimeGridWeekCard from "./TimeGridWeekCard";
import DayGridMonthCard from "./DayGridMonthCard/index";
import AppoGridView from "./AppoGridView/index";

import moment from "moment";

import "./index.css";
import { useHistory } from "react-router-dom";
import MobileWeekList from "./MobileWeekList";
import { CkModal } from "../../../CkUI";
import { WorkshopAPI } from "../../Services/axios";

interface IProps {
  workshopId: string | null;
  workshopServices: WorkshopService[];
  workshopWorkingHours: WorkHours[];
  packagesList: Items[];
  showDrawer: boolean;
  hours: WorkHours[];
  closeDrawer: Function;
  viewQuizModal: Function;
  showInProgressModal: Function;
  dsrStatusCode: string | null;
  coordinates: string;
}

let initialDate: any = null;
let finalDate: any = null;

let intervalID: any = null;

const initializeAppointmentFilters = () => {
  let filters: { [id: string]: boolean } = {};
  filters["CREAT"] = true;
  filters["PEND"] = true;
  filters["APPO"] = true;
  filters["RECEP"] = true;
  filters["DIAG"] = true;
  filters["QUOT"] = true;
  filters["PROG"] = true;
  filters["READY"] = true;
  filters["CLOS"] = true;
  filters["CANCE"] = true;

  return filters;
};

const MemoizedExxonCallendar: React.FC<IProps> = ({
  workshopId,
  workshopWorkingHours,
  hours,
  coordinates,
  // unused
  workshopServices,
  packagesList,
  showDrawer,
  closeDrawer,
  viewQuizModal,
  showInProgressModal,
  dsrStatusCode,
}) => {
  const user = React.useContext(CurrentUser);
  const isMobileScreen = useIsMobileScreen(992);
  const dispatch = useDispatch();
  const appointments = useSelector(getAppointments);
  const notificationNumber = useSelector(getNotificationsNumber);
  const size = useWindowSize();
  const history = useHistory();

  const [isRecentlyApproved, setIsRecentlyApproved] = useState<boolean>(false);

  const calendarRef = React.createRef<FullCalendar>();
  // const popoverEventRef = useRef<any>(null);

  const [eventInfo, setEventInfo] = useState(null);

  const [showModalInfo, setShowModalInfo] = useState(false);

  const [calendarTitle, setCalendarTitle] = useState<string>("");
  const [calendarDateInfo, setCalendarDateInfo] = useState<any>({
    startDay: null,
    endDay: null,
  });
  const [calendarEvt, setCalendarEvt] = useState([]);

  const [calendarView, setCalendarView] = useState<string>("listMonth");
  const [showWeekends, setShowWeekends] = useState<boolean>(true);
  const [canEditStateComments, setCanEditStateComments] = useState(false);

  const [canEdit, setCanEdit] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showAllAppos, setShowAllAppos] = useState(true);

  const [responsiveConfiguration, setResponsiveConfiguration] = useState<{
    dayMaxEventRows: number;
    eventMaxStack: number;
  }>({
    dayMaxEventRows: 1,
    eventMaxStack: 1,
  });

  const [minHour, setMinHour] = useState<number>(0);
  const [maxHour, setMaxHour] = useState<number>(24);

  const [appointmentFilters, setAppointmentFilters] = useState<{
    [id: string]: boolean;
  }>(initializeAppointmentFilters());

  const checkRecentlyApproved = () => {
    if (workshopId && coordinates) {
      const currentTimeZone = new Date().getTimezoneOffset().toString();
      WorkshopAPI.getByIdAndCoord(
        +workshopId,
        coordinates,
        currentTimeZone
      ).then((result) => {
        if (result?.data?.isRecentlyApproved) {
          setIsRecentlyApproved(true);
        }
      });
    }
  };

  useEffect(() => {
    checkRecentlyApproved();
  }, [workshopId, notificationNumber]);

  useEffect(() => {
    checkRecentlyApproved();
  }, []);

  useEffect(() => {
    if (workshopWorkingHours && workshopWorkingHours.length > 0) {
      const earliestOpeningTime = getEarliestOpeningTime(workshopWorkingHours);

      setMinHour(parseInt(subtractHalfHourIfHalf(earliestOpeningTime)));

      const latestClosingTime = getLatestClosingTime(workshopWorkingHours);
      let lastHour: number = parseInt(addHalfHourIfHalf(latestClosingTime));

      if (lastHour === 24) {
        setMaxHour(lastHour);
      } else {
        setMaxHour(lastHour + 1);
      }
    }
  }, [workshopWorkingHours]);

  useEffect(() => {
    if (!!calendarRef.current === false) return;
    const currentViewType =
      calendarRef.current?.getApi()?.getCurrentData().currentViewType || "";
    const configuration = { ...responsiveConfiguration };
    switch (currentViewType) {
      case "timeGridDay":
        if (size.width < 576) {
          configuration.eventMaxStack = 2;
        } else if (size.width >= 576 && size.width < 768) {
          configuration.eventMaxStack = 3;
        } else if (size.width >= 768 && size.width < 1200) {
          configuration.eventMaxStack = 3;
        } else {
          configuration.eventMaxStack = 4;
        }
        configuration.dayMaxEventRows = 1;
        break;
      case "timeGridWeek":
        configuration.eventMaxStack = size.width < 1330 ? 1 : 2;
        configuration.dayMaxEventRows = size.width < 1330 ? 1 : 2;
        break;
      case "dayGridMonth":
        configuration.eventMaxStack = size.width < 992 ? 0 : 3;
        configuration.dayMaxEventRows = size.width < 992 ? 0 : 4;
        break;
      case "listMonth":
        break;
    }
    setResponsiveConfiguration(configuration);
  }, [size, calendarView]);

  useEffect(() => {
    if (refresh) {
      checkAccess();
      // fetchAppoitnmentStatusData();
      fetchEventData();
    }
  }, [refresh]);

  useEffect(() => {
    checkAccess();
    GetCalendarTitle();
    dispatch(setCurrentSection(""));
    // fetchAppoitnmentStatusData();
    intervalID = setInterval(() => {
      fetchEventData();
    }, 120000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    let events: any = [];
    let appointmentCount: { [id: string]: number } = {};

    appointments.forEach((appointment: Appointment) => {
      if (
        appointment.statusCode !== "CREAT" ||
        (appointment.statusCode === "CREAT" &&
          appointment.createdSource === "W")
      ) {
        if (appointmentFilters[appointment.statusCode]) {
          let title =
            appointment.vehicle.brandName && appointment.vehicle.modelName
              ? `${appointment.vehicle.brandName} - ${appointment.vehicle.modelName}`
              : appointment.vehicle.licensePlate;
          let color = getColorByStatusId(appointment.statusCode);
          appointmentCount[appointment.statusCode] = appointmentCount[
            appointment.statusCode
          ]
            ? appointmentCount[appointment.statusCode] + 1
            : 1;

          const startTime = moment(appointment.startDateTime).set({
            second: 0,
            millisecond: 0,
          });
          const endTime = moment(appointment.endDateTime).set({
            second: 0,
            millisecond: 0,
          });

          events.push({
            title,
            start: Date.parse(startTime.toISOString()), //Date.parse(appointment.startDateTime),
            end: Date.parse(endTime.toISOString()), //Date.parse(appointment.endDateTime),
            // borderColor: color,
            borderColor: "transparent",
            textColor: color,
            // textColor: "black",
            backgroundColor: "transparent",
            display: "block",
            classNames: `custom_events_calendar--${appointment.statusCode}`,
            // source: { ...appointment },
            ...appointment,
          });
        }
      }
    });

    setCalendarEvt(events);
  }, [appointments, appointmentFilters]);

  useEffect(() => {
    const allActive = Object.keys(appointmentFilters).some(
      (key) => !appointmentFilters[key]
    );
    if (allActive) {
      if (!showAllAppos) setShowAllAppos(true);
    } else {
      if (showAllAppos) setShowAllAppos(false);
    }
  }, [appointmentFilters]);

  useEffect(() => {
    document.title = "CarKer Para Talleres Automotrices";
  }, []);

  const fetchEventData = async () => {
    Promise.resolve(
      dispatch(
        fetchAppointments({
          workshopId: workshopId!,
          initialDate: initialDate.toISOString(),
          finalDate: finalDate.toISOString(),
        })
      )
    ).finally(() => {
      setRefresh(false);
    });
  };

  const checkAccess = () => {
    if (
      user?.userRoles.find((role) => role.roleCode === "OWN") !== undefined ||
      user?.userRoles.find((role) => role.roleCode === "ADM") !== undefined
    ) {
      setCanEdit(true);
      setCanEditStateComments(true);
    } else {
      setCanEdit(false);
      setCanEditStateComments(true);
    }
  };

  const getColorByStatusId = (id: string) => {
    switch (id) {
      case "CREAT":
        return "var(--CREAT-color)";
      case "PEND":
        return "var(--PEND-color)";
      case "APPO":
        return "var(--APPO-color)";
      case "RECEP":
        return "var(--RECEP-color)";
      case "DIAG":
        return "var(--DIAG-color)";
      case "QUOT":
        return "var(--QUOT-color)";
      case "PROG":
        return "var(--PROG-color)";
      case "READY":
        return "var(--READY-color)";
      case "CLOS":
        return "var(--CLOS-color)";
      case "CANCE":
        return "var(--CANCE-color)";
      default:
        break;
    }
  };

  const HandlerEvtClick = (eventClickInfo: any) => {
    setEventInfo(eventClickInfo.event._def);
    history.push(`/cita/${eventClickInfo.event._def.publicId}`);
  };

  const HandlerEvtClickMobile = (eventClickInfo: any) => {
    setEventInfo(eventClickInfo);
    history.push(`/cita/${eventClickInfo.id}`);
  };

  const SimulateEventClick = (data: any) => {
    const newEventClick = {
      event: {
        context: {},
        _def: {
          groupId: "",
          url: "",
          recurringDef: null,
          defId: "",
          sourceId: "",
          allDay: false,
          hasEnd: true,
          ui: {},
          title: "",
          start: "",
          end: "",
          display: "",
          backgroundColor: "",
          borderColor: "",
          textColor: "",
          classNames: [],
          extendedProps: {}, // This property must be assigned using Info
          publicId: "", // This is the appointment Id property must be assigned using Info
          id: "", // This is the appointment Id property must be assigned using Info
        },
        instance: {},
      },
      view: {},
      timeText: "",
      textColor: "",
      backgroundColor: "transparent",
      borderColor: "transparent",
      isDraggable: false,
      isStartResizable: false,
      isEndResizable: false,
      isMirror: false,
      isStart: true,
      isEnd: true,
      isPast: true,
      isFuture: false,
      isToday: false,
      isSelected: false,
      isDragging: false,
      isResizing: false,
    };
    newEventClick.event._def.id = data.id;
    newEventClick.event._def.publicId = data.id;
    newEventClick.event._def.extendedProps = data;
    HandlerEvtClick(newEventClick);
  };

  const HandleChange = (value: string) => {
    setCalendarView(value);

    let calendarApi = calendarRef.current
      ? calendarRef.current.getApi()
      : undefined;
    calendarApi && calendarApi.changeView(value);
    GetCalendarTitle();
  };

  const CapitalizeText = (text: string) => {
    const str = text;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    return str2;
  };

  const GetCalendarTitle = () => {
    const calendarApi = calendarRef.current!.getApi();
    const viewType = calendarApi.view.type;

    if (viewType !== "listMonth") {
      initialDate = calendarApi.view.activeStart;
      finalDate = calendarApi.view.activeEnd;
    } else {
      // Get 1 month before and 1 mont after today
      initialDate = moment(calendarApi.view.activeStart)
        .subtract(1, "month")
        .startOf("month")
        .toDate();
      finalDate = moment(calendarApi.view.activeEnd).endOf("month").toDate();
    }

    fetchEventData();
    let title = "";
    let startDay;
    let endDay;
    let date;
    switch (viewType) {
      case "timeGridDay":
        date = calendarApi.view.currentStart;
        title = `${CapitalizeText(
          moment(date).format("dddd DD")
        )} de ${CapitalizeText(moment(date).format("MMMM YYYY"))}`;
        break;
      case "timeGridWeek":
        startDay = calendarApi.view.currentStart;
        endDay = calendarApi.view.currentEnd;
        endDay.setDate(endDay.getDate() - 1);
        setCalendarDateInfo({
          startDay: moment(startDay),
          endDay: moment(endDay),
        });

        title = `Semana del ${moment(startDay).format("DD")} al ${moment(
          endDay
        ).format("DD")} de ${CapitalizeText(
          moment(endDay).format("MMMM YYYY")
        )}`;
        break;
      case "listMonth":
        date = calendarApi.view.currentStart;
        title = CapitalizeText(moment(date).format("MMMM YYYY"));
        break;
      case "dayGridMonth":
        date = calendarApi.view.currentStart;
        title = CapitalizeText(moment(date).format("MMMM YYYY"));
        break;
      default:
        break;
    }
    setCalendarTitle(title);
  };

  const changeCalendarPage = (page: string) => {
    let calendarApi = calendarRef.current
      ? calendarRef.current.getApi()
      : undefined;
    if (page === "next") calendarApi && calendarApi.next();
    else if (page === "prev") calendarApi && calendarApi.prev();
    else if (page === "today") calendarApi && calendarApi.today();

    GetCalendarTitle();
  };

  const setFilter = (e: CheckboxChangeEvent, state: string) => {
    let filters = { ...appointmentFilters };
    filters[state] = e.target.checked;
    setAppointmentFilters(filters);
  };

  const GetInfo = (Info: any, type: string) => {
    switch (type) {
      case "timeGridDay":
        return <TimeGridDayCard Info={Info} />;
      case "timeGridWeek":
        return <TimeGridWeekCard Info={Info} />;
      case "dayGridMonth":
        return <DayGridMonthCard Info={Info} />;
      default:
        return null;
    }
  };

  const getDayHeaderFormat = (view: string) => {
    if (view === "timeGridWeek") return { weekday: "short", day: "numeric" };
    if (view === "dayGridMonth") return { weekday: "short" };
    return {};
  };

  // const AppointmentPopover = () => {
  //   const [data, setData] = useState<any>();
  //   const tooltipRef = useRef<HTMLElement>(null);

  //   useEffect(() => {
  //     document.addEventListener(customEventName, () => {
  //       setData(
  //         popoverEventRef.current
  //           ? popoverEventRef.current?.event?._def?.extendedProps
  //           : null
  //       );
  //     });
  //     window.addEventListener("scroll", () => {
  //       if (
  //         data === null ||
  //         tooltipRef.current === null ||
  //         popoverEventRef.current === null
  //       )
  //         return;
  //       // @ts-ignore
  //       if (tooltipRef.current?.getPopupDomNode() === null) return;
  //       console.info("popoverEventRef.current", popoverEventRef.current);
  //       console.info("tooltipRef.current", tooltipRef.current);
  //       // @ts-ignore
  //       // tooltipRef.current?.getContainer && tooltipRef.current?.getContainer()
  //       // @ts-ignore
  //       positionTooltipAboveReference(
  //         tooltipRef.current?.getPopupDomNode(),
  //         popoverEventRef.current.el
  //       );
  //     });
  //   }, []);

  //   useEffect(() => {
  //     if (
  //       data === null ||
  //       tooltipRef.current === null ||
  //       popoverEventRef.current === null
  //     )
  //       return;
  //     // @ts-ignore
  //     if (tooltipRef.current?.getPopupDomNode() === null) return;
  //     console.info("popoverEventRef.current", popoverEventRef.current);
  //     console.info("tooltipRef.current", tooltipRef.current);
  //     // @ts-ignore
  //     // tooltipRef.current?.getContainer && tooltipRef.current?.getContainer()
  //     // @ts-ignore
  //     positionTooltipAboveReference(
  //       tooltipRef.current?.getPopupDomNode(),
  //       popoverEventRef.current.el
  //     );
  //   }, [data]);

  //   const positionTooltipAboveReference = (
  //     tooltipNode: HTMLElement,
  //     referenceNode: HTMLElement
  //   ) => {
  //     console.info("tooltipNode", tooltipNode);
  //     console.info("referenceNode", referenceNode);
  //     const referenceRect = referenceNode.getBoundingClientRect();
  //     const tooltipRect = tooltipNode.getBoundingClientRect();

  //     console.info("referenceRect", referenceRect);
  //     console.info("tooltipRect", tooltipRect);

  //     const tooltipHeight = tooltipRect.height;

  //     console.info("tooltipHeight", tooltipHeight);

  //     const top = referenceRect.top; /*  - tooltipHeight */
  //     const left =
  //       referenceRect.left + referenceRect.width / 2 - tooltipRect.width / 2;

  //     console.info("top", top);
  //     console.info("left", left);

  //     tooltipNode.style.position = "absolute";
  //     tooltipNode.style.top = `${top}px !important`;
  //     tooltipNode.style.left = `${left}px !important`;
  //   };

  //   const getTooltipContainer = () => {
  //     const node = popoverEventRef.current?.el || document.body;
  //     console.info("node", node);
  //     return node;
  //   };

  //   const close = () => setData(null);

  //   const Content = () => (
  //     <>
  //       <Button type="ghost" onClick={close} icon={<CKCloseIcon />} />
  //       <h2 className="appointment-status">{data && data?.statusName}</h2>
  //       <p className="appointment-date">
  //         {data &&
  //           moment(data.startDateTime)
  //             .format("DD [de] MMMM, hh:mm A")
  //             .toString()}
  //       </p>
  //       <div className="appointment-data-group">
  //         <p className="label">Vehículo</p>
  //         <p className="value">
  //           {data &&
  //             `${data.vehicle.brandName} ${data.vehicle.modelName} ${data.vehicle.year}`}
  //         </p>
  //       </div>
  //       <div className="appointment-data-group">
  //         <p className="label">Paquete o servicio:</p>
  //         <p className="value">
  //           {data &&
  //             [
  //               ...data.packages.map((pack: any) => pack.servicePackageDesc),
  //               ...data.services.map((service: any) => service.serviceName),
  //             ].join(", ")}
  //         </p>
  //       </div>
  //       <Button
  //         className="--custom"
  //         onClick={() => {
  //           setEventInfo(popoverEventRef.current.event._def);
  //           //setShowModalInfo(true);
  //         }}
  //       >
  //         Ver detalles
  //       </Button>
  //     </>
  //   );

  //   if (data === null) return null;

  //   return (
  //     <Tooltip
  //       ref={tooltipRef}
  //       open={!!data}
  //       autoAdjustOverflow
  //       trigger="click"
  //       /* getTooltipContainer={getTooltipContainer} */
  //       /* This is for the popover */
  //       /* content={<Content />}
  //       title={null}
  //       overlayClassName="appointment-popover" */
  //       /* This is for the tooltip */
  //       title={<Content />}
  //       onOpenChange={(visible) => console.info("onOpenChange", visible)}
  //       overlayClassName="appointment-tooltip"
  //     />
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>CarKer Para Talleres Automotrices</title>‍
        <meta
          name="description"
          content="Digitaliza tu taller automotriz formando parte de la plataforma CarKer y conecta con más clientes."
          data-react-helmet="true"
        />
      </Helmet>

      <div
        className={
          calendarView !== "listMonth"
            ? "calendar-page overflow-hidden"
            : "calendar-page"
        }
      >
        <div className="calendar-container">
          <CalendarHeader
            refresh={refresh}
            showAllAppos={showAllAppos}
            calendarView={calendarView}
            showWeekends={showWeekends}
            setShowWeekends={setShowWeekends}
            appointmentFilters={appointmentFilters}
            calendarTitle={calendarTitle}
            setFilter={setFilter}
            setRefresh={setRefresh}
            setAppointmentFilters={setAppointmentFilters}
            changeCalendarPage={changeCalendarPage}
            HandleChange={HandleChange}
          />

          {refresh && (
            <div className="spin-container">
              <Spin size="large" />
            </div>
          )}

          {calendarView === "listMonth" && (
            <AppoGridView
              list={calendarEvt}
              hours={hours}
              SimulateEventClick={SimulateEventClick}
              hidden={calendarView !== "listMonth"}
              workshopId={workshopId}
              setRefresh={setRefresh}
            />
          )}

          <CkModal
            title={"Tu taller ha sido aprobado"}
            children={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  Todo está listo para que empieces a gestionar tus citas con
                  CarKer.
                </p>
              </div>
            }
            open={isRecentlyApproved}
            actionButtonsDirection="column"
            primaryAction={{
              label: "Continuar",
              onClick: () => {
                WorkshopAPI.setRecentlyApprovedToFalse(workshopId).then(
                  (result) => {
                    setIsRecentlyApproved(false);
                  }
                );
              },
              order: 1,
            }}
            onCancel={() => setIsRecentlyApproved(false)}
            style={{ top: "40%" }}
          />

          <MobileWeekList
            events={calendarEvt}
            calendarRange={calendarDateInfo}
            handleClick={HandlerEvtClickMobile}
            hidden={calendarView !== "timeGridWeek"}
            isMobile={isMobileScreen}
          />
          <div style={{ display: calendarView === "listMonth" ? "none" : "" }}>
            <FullCalendar
              ref={calendarRef}
              viewClassNames={[
                "calendar-view",
                ...(calendarView === "listMonth" ? ["hidden"] : []),
                ...(calendarView === "timeGridWeek" && isMobileScreen
                  ? ["calendar-hidden"]
                  : []),
              ].join(" ")}
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
                listPlugin,
              ]}
              initialView="listMonth"
              headerToolbar={false}
              nowIndicator
              weekends={showWeekends}
              buttonText={{
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              titleFormat={{
                year: "numeric",
                month: "long",
                day: "numeric",
              }}
              dayHeaders={calendarView !== "timeGridDay"}
              dayHeaderFormat={getDayHeaderFormat(calendarView)}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: true,
                meridiem: "short",
              }}
              slotMinTime={{ hour: minHour }}
              slotMaxTime={{ hour: maxHour }}
              locale="es"
              slotLabelContent={(e) => {
                return (
                  <p>
                    <span>{moment(e.date).format("h")}</span>{" "}
                    <span>{moment(e.date).format("a")}</span>
                  </p>
                );
              }}
              events={calendarEvt}
              eventContent={(e) => GetInfo(e, e.view.type)}
              eventClick={HandlerEvtClick}
              noEventsContent="No existen citas"
              //editable={true}
              //selectable={true}
              allDaySlot={false}
              eventOverlap={true}
              // dateClick={HandlerDateClick}
              //eventChange={HandlerEvtChange}
              slotEventOverlap={false}
              dayMaxEventRows={responsiveConfiguration.dayMaxEventRows}
              eventMaxStack={responsiveConfiguration.eventMaxStack}
              // dayMaxEventRows={5}
              // eventMaxStack={1}
              moreLinkContent={(ev) => {
                const text = ev.num > 1 ? "citas" : "cita";
                return `${ev.shortText} ${text}`;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const ExxonCallendar = React.memo(MemoizedExxonCallendar);
