import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UsesOfCarKer } from "./Screens/MobilContigo LandingPage/UsesOfCarKer";
import { Disclaimer } from "./Screens/LandingPage/Disclaimer";
import { LoginPage } from "./Screens/LoginPage/LoginPage";
import { Terms } from "./Screens/LandingPage/Terms";
import {LandingPage} from "./Screens/LandingPage";

const UnauthenticatedApp = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <LandingPage />
      </Route>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/registro">
        <LoginPage defaultBox="register" />
      </Route>
      <Route path="/terminos">
        <Terms />
      </Route>
      <Route path="/avisos-legales-CarKerApp">
        <UsesOfCarKer />
      </Route>
      <Route path="/usos-de-carker-CarKerApp">
        <Disclaimer />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default UnauthenticatedApp;
